import React from "react";
import { Box, Paragraph} from "grommet";
import corona2 from "../images/logo5.jpg"
import './NotFound.css';


export const NotFound = () => (
  <Box fill align="center" pad={{ top: "large", horizontal: "small" }}>
      <Box style={{ flexDirection: 'row', justifyContent:'space-between',padding:"large"}}>
        {/*  <Text style={{fontSize: 100,lineHeight: 50, fontWeight: "bold", color:"#76B7B2" }}>
           C
          </Text>

      <img src={ corona2} className="App-logo" alt= "" />
          <Text style={{fontSize: 100, lineHeight: 50, fontWeight: "bold", color:"#76B7B2"}}>
              RDITE
          </Text>
*/}

          <img src={ corona2} className="nf" alt= "" />



  </Box>


    <Paragraph textAlign="center" >
        The page you requested does not exist or has been removed.
    </Paragraph>
  </Box>
);
