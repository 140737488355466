import React, {Component} from "react";
import {render} from "react-dom";
import {BrowserRouter, Route, Switch} from "react-router-dom";

import {theme} from "./theme";
//import "./Design.css"
import { NotFound, About, Access, Disclosure, Peptides, AMP,Sequences, Overview} from "./pages";
import {Box, Grid, Grommet, Main, Text} from 'grommet';
import Container from '@material-ui/core/Container';
import Divider from "@material-ui/core/Divider";
import Navigation from "./components/Navigation";
import {LoadingBar} from "./LoadingBar";

import {Header} from "./Header";
import Button from "@material-ui/core/Button";
import compass from "./images/logo3.jpg";
import url from "./data/url.json";


const items = [

    {
        name: "  About",
        pathto: "/",
        value: 1,
    },
    {
        name: "  Peptides",
        pathto: "/Peptides",
        value: 2,
    },

    {
        name: "  Sequences",
        pathto: "/Sequences",
        value: 3,
    },
    {
        name: "  API",
        pathto: "/access",
        value: 4,
    },
    {
        name: "  Overview",
        pathto: "/Overview",
        value: 4,
    },
    {
        name: "  Disclosure",
        pathto: "/disclosure",
        value: 5,
    }

];

//const url = require("./data/url");

class App extends Component {

  constructor(props) {
          super(props);
          this.state = {

              peptidesData: [],
              sequencesData: [],
              overviewData: [],

              loading: true
          };
      }

      //state = { loading: true };

      componentDidMount() {
          let url = require("./data/url");
          Promise.all([

              fetch(url.peptides),


          ])
              .then(([res1]) => Promise.all([res1.json()]))
              .then(([peptidesData]) => this.setState({

                  peptidesData: peptidesData.data,


                  loading:false,
              }))
              .catch(error => console.log(`Error in promises ${error}`))

          Promise.all([

              fetch(url.sequences),


          ])
              .then(([res1]) => Promise.all([res1.json()]))
              .then(([sequencesData]) => this.setState({

                  sequencesData: sequencesData.data,


                  loading:false,
              }))
              .catch(error => console.log(`Error in promises ${error}`))
          Promise.all([

              fetch(url.overview),


          ])
              .then(([res1]) => Promise.all([res1.json()]))
              .then(([overviewData]) => this.setState({

                  overviewData: overviewData.data,


                  loading:false,
              }))
              .catch(error => console.log(`Error in promises ${error}`))


      }

    render() {

/*        function DetailedPage({match}) {


            return <AMP
                //data={peptidesData+ match.params.id}
            />
                ;
        }*/

        const {peptidesData,sequencesData,overviewData, loading} = this.state;


        return (

            <Grommet theme={theme} full>
                <Grid fill rows={["auto", "flex", "auto"]}>
                    {/*<Header/>*/}

                    <Divider/>
                    <Box>

                        <Grommet theme={theme} full>
                            <Grid fill rows={["auto", "flex", "auto"]}>


                                <BrowserRouter>

                                    <Box  direction="row" full justify={"start"} align="start"
                                         background={"white"}>



                                        <Navigation items={items}/>

                                    </Box>
                                    {/* <Divider/>
                                            <Box pad="medium">*/}
                                    <Main>
                                        {loading ? <LoadingBar/> :
                                            <Container maxWidth={"xl"}>

                                                <Switch>

                                                    <Route path="/" exact component={About}/>


                                                    <Route path="/access" component={Access}/>

                                                    <Route path="/Peptides"> 
                                                              < Peptides
                                                                  data={peptidesData}
                                                              />
                                                          </Route>

                                                    <Route path="/Sequences">
                                                        < Sequences
                                                            data={sequencesData}
                                                        />
                                                    </Route>
                                                    <Route path="/Overview">
                                                        < Overview
                                                            data={overviewData}
                                                        />
                                                    </Route>


                                                    <Route path="/AMP">
                                                              < AMP
                                                                  data={peptidesData}
                                                              />
                                                          </Route>
                                         {/*           <Route path="/AMP:id" component={DetailedPage}/>*/}

                                                    <Route path="/disclosure">
                                                        <Disclosure/>
                                                    </Route>


                                                    <Route component={NotFound}/>

                                                </Switch>

                                            </Container>}
                                    </Main>
                                </BrowserRouter>

                            </Grid>

                        </Grommet>


                    </Box>
                    {/*  </Grommet>


                    </Box>*/}
                    <Divider></Divider>
                    <Box tag="footer" pad="xxsmall"  color="#e9e6df" background={"153, 198, 97,0.5"}>
                        <Box style={{flex: 1,flexDirection: 'row', justifyContent: 'space-between'}}>


                            <Text style={{color: "#000000"}}>{"\t"} <a href={"/disclosure"}> Disclosure</a>
                            </Text>

                            <Text style={{color: "#000000"}}>{"\t"} <a href="https://www.uni-marburg.de/en/privacy"
                                                                       rel="noopener noreferrer"
                                                                       target="_blank"> Privacy Statement</a>
                            </Text>


                            <Text color="#000000" style={{paddingRight: 10}}> v0.0.1 </Text>
                        </Box>
                    </Box>

                </Grid>
            </Grommet>
        );
    }
}

render(<App/>, document.getElementById("root"));

