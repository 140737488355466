import React, {Component} from "react";
import {Box, Heading} from "grommet";
import "./Details.css"
import LinkedElement from "./LinkedElement";
import Element from "./Element";




export class MoleculeDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drugData: {},
        };
    }

    componentDidMount() {
        const {url} = this.props;
        fetch(url)
            .then(response => response.json())
            .then(drugData => this.setState({drugData: drugData}));

    }


    render() {
        const {heading} = this.props;
        const {pubchem, pdb, drugbank, atc} = this.props;
        const {drugData} = this.state;
        const ref =drugData.references;

        console.log(ref);
        return (

            <Box fill align="start" justify="center" background={"#fafafa"} pad="small">
                <Heading
                    color={"#76B7B2"} level={2}>
                    {heading}</Heading>

                <h2>
                    Tag: {drugData.tags}
                </h2>

            {/*    <Element
                name={"Gene"}
                id={drugData.gene}
                />*/}

                <Element
                    name={"Smile"}
                    id={drugData.smile}
                />

                <Element
                    name={"Description"}
                    id={drugData.description}
                />

  {/*              <Element
                    name={"Specie"}
                    id={drugData.specie}
                />*/}

                <LinkedElement
                    name={"Uniprot"}
                    id={drugData.uniprot}
                    link={"https://www.uniprot.org/uniprot/"}
                />

                <LinkedElement
                    name={"PubChem"}
                    id={pubchem}
                    link={"https://pubchem.ncbi.nlm.nih.gov/compound/"}
                />
                <LinkedElement
                    name={"RCSB PDB"}
                    id={pdb}
                    link={"http://www.rcsb.org/structure/"}
                />
                <LinkedElement
                    name={"Drugbank"}
                    id={drugbank}
                    link={"https://www.drugbank.ca/drugs/"}
                />
                <LinkedElement
                    name={"ATC"}
                    id={atc}
                    link={"https://www.whocc.no/atc_ddd_index/?code="}
                />
                <div>
                    <h2>Referenced peer-reviewed research articles</h2>

                    <h3>
                       Computational:
                </h3>
                    <span>
                        positive: {drugData.ref_Computational_positive_nr} | negative: {drugData.ref_Computational_negative_nr}
                    </span>


                    <h3>
                        In vitro:
                    </h3>


                    <span>
                        positive: {drugData.ref_Invitro_positive_nr} | negative: {drugData.ref_Invitro_negative_nr}
                    </span>

                    <h3>
                        In vivo:
                    </h3>
                    <span>
                        positive: {drugData.ref_Invivo_positive_nr} | negative: {drugData.ref_Invivo_negative_nr}
                    </span>


                    <h3>
                        Clinical trail:
                    </h3>
                    <span>
                        positive: {drugData.ref_Clinicaltrial_positive_nr} | negative: {drugData.ref_Clinicaltrial_negative_nr}
                    </span>


                    </div>

            </Box>

        );
    }
}
