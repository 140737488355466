import React, {Component} from "react";
import {Box, Heading} from "grommet";
import {GroupableTable} from "./content";
import { Target} from "../icons";
import {Details} from "./details/description/";
import ReactDOM from 'react-dom/client';
import Example from './content/RemoteTable';




import { Link } from 'react-router-dom';
//import {LoadingBar} from "../LoadingBar";

//const url = require("../data/url");
export class Peptides extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            //loading:true
        };
    }

    //state = { loading: true };

/*    componentDidMount() {

        fetch(url.drugs)
            .then(response => response.json())
            .then(data => this.setState({data:data.data,
                loading:false
            })).catch(error => console.log(`Error in promises ${error}`));

    }*/





    render() {
       let url = require(".././data/url");
        const {data} = this.props;


        //const {data,loading} = this.state;

        return (





            <Box   justify="center" >
                <Heading>Peptides</Heading>
                <Example ></Example>,



                <span>&nbsp;&nbsp;</span>
                                <span>&nbsp;&nbsp;</span>
            </Box>


        );
    }
}
