import React, {Component} from "react";
import {Box, Heading, Text} from "grommet";
import {GroupableTable} from "./content";
import SequenceTable from './content/SequenceTable';

import {Details} from "./details/description/";

import { Link } from 'react-router-dom';
import url from "../data/url.json";
import Example from "./content/RemoteTable";
//import {LoadingBar} from "../LoadingBar";

//const url = require("../data/url");
export class Sequences extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            //loading:true
        };
    }

    //state = { loading: true };

/*    componentDidMount() {

        fetch(url.drugs)
            .then(response => response.json())
            .then(data => this.setState({data:data.data,
                loading:false
            })).catch(error => console.log(`Error in promises ${error}`));

    }*/





    render() {
       let url = require(".././data/url");
        const {data} = this.props;


        //const {data,loading} = this.state;

        return (
            <Box fill  justify="center">
                <Heading>Sequences</Heading>

                <SequenceTable></SequenceTable>,


                <span>&nbsp;&nbsp;</span>
                                <span>&nbsp;&nbsp;</span>
            </Box>


        );
    }
}
