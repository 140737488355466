import React, {Component} from "react";
import {Box, Heading , Text} from "grommet";
import "./peptide.css"


export class AMP extends Component {

    render() {
        
        let url = require(".././data/url");
                   
        const {data} = this.props;
        let id = window.location.href.split("/").slice(-1)[0];
        let data_set = false;
        for (let i = 0; i < data.length; i++) {
          if (data[i]["sequence_id"] == id){
            data_set = data[i]
          }
        }
        let from_db = [];
        let from_db_id = [];
        
        if (data_set["dbaasp_id"] !== "" ){from_db.push({name:"dbaasp",id:data_set["dbaasp_id"],link:"https://dbaasp.org/peptide-card?id="});}
        if (data_set["dramp_id"] !== "" ){from_db.push({name:"dramp",id:data_set["dramp_id"],link:"http://dramp.cpu-bioinfor.org/browse/All_Information.php?id="});}
        if (data_set["uniprot_id"] !== "" ){from_db.push({name:"uniprot",id:data_set["uniprot_id"],link:"https://www.uniprot.org/uniprotkb/"});}
        if (data_set["camp_id"] !== "" ){from_db.push({name:"camp",id:data_set["camp_id"],link:"http://www.camp3.bicnirrh.res.in/seqDisp.php?id="});}
        if (data_set["yadamp_id"] !== "" ){from_db.push({name:"yadamp",id:data_set["yadamp_id"]});}
        if (data_set["apd3_id"] !== "" ){from_db.push({name:"apd3",id:data_set["apd3_id"]});}
        if (data_set["dbamp_id"] !== "" ){from_db.push({name:"dbamp",id:data_set["dbamp_id"],link:"https://awi.cuhk.edu.cn/dbAMP/information.php?db="});}
        
        console.log("adasddsa");
        console.log(data)
        

        return (
          

            <Box fill align="start" justify="center">
                <Heading> {data_set["sequence_name"]} </Heading>
             
             <Box style={{flexDirection: 'row', padding: 0}}>
             
             
             
             <Box style={{flexDirection: 'col', padding: 0}}>
             <Box style={{flexDirection: 'row', padding: 0}}>
                    <Text style={{fontSize: 20, fontWeight: "bold"}}>
                        Source:&nbsp;
                    </Text>
                    <Text style={{fontSize: 20}}>
                        {data_set["peptide_source"]}
                    </Text>
                                    
                </Box>
                    
                
                
              <Box style={{flexDirection: 'row', padding: 0}}>
                    <Text style={{fontSize: 20, fontWeight: "bold"}}>
                        Sequence:&nbsp;
                    </Text>
                    <Text style={{fontSize: 20}}>
                        {data_set["sequence"]}<br/>
                    </Text>
                </Box>
                   
                    
                    
                  <div>
                  </div>
                    
  <div >
      {from_db.map((from_db_, index) => (
        <div key={index}>
          <Text style={{fontSize: 20, fontWeight: "bold"}}>
                        {from_db_.name}:
                    </Text>
                    <Text style={{fontSize: 20}}>
                        <a href={from_db_.link+from_db_.id}>{from_db_.id}</a>
                    </Text>

        </div>
      ))}
    </div>        
                
</Box>
                    </Box>



            </Box>
        );
    }
}
