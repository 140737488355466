import React from 'react';
import {Box} from "grommet";
import RoutedTab from "./RoutedTab";
import "../Header.css"
import {Tabs} from "@material-ui/core";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import compass from "../images/logo5.jpg";



const theme = createTheme({
    palette: {
        primary: {main: "#9cca5e",},
        secondary: {main: "#000000",},
    },
    typography: {useNextVariants: true},
});


export default function Sidebar(props) {

    const [value, setValue] = React.useState(1);
    const [expanded] = React.useState(true);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

/*    const handleExpandClick = () => {
        setExpanded(!expanded);
    };*/


    /*const routedNav = props.items.map(({icon, pathto, value}) => (
        <RoutedNav
            key={value}
            Icon={icon}
            path={pathto}
        />));*/

    const routedTab = props.items.map(({icon, name, pathto, value}) => (
        <RoutedTab
            key={value}
            value={value}
            symbol={icon}
            path={pathto}
            label={name}
        />));



    /*
        const Collapsed = () => (

            <MuiThemeProvider theme={theme}>
                <div>
                    <Box orientation="vertical"
                         onChange={handleChange}>
                        {routedNav}
                    </Box>
                </div>
            </MuiThemeProvider>


        );
    */


    if (expanded) {
        return <Box
            fill="horizontal"
            //width="250px"
            //height="6vw"
            //background="white"
            elevation="medium" overflow="auto" direction="column"  align-items={ "flex-end"} >

            <ThemeProvider theme={theme}>
<Box align-items={ "flex-end"} fill={"horizontal"} direction="row"  className={"xyz"}>
                <Button  href="/" color="primary">
                    <img className={"logo"} src={compass}  alt=""/>
                </Button>
                <Box align-items={ "flex-end"}>
                    <Tabs
                        variant="fullWidth"
                        centered
                        align-items={ "flex-end"}

                        orientation="horizontal"
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"                    >
                        {routedTab}

                    </Tabs>
                </Box>
        </Box>
            </ThemeProvider>



        </Box>;
    }
    return <Box fill="horizontal" overflow="auto" >



    </Box>;
}



