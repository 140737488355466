import React, {Component} from "react";
import {Box, Heading, Text} from "grommet";
import {GroupableTable} from "./content";
import { Target} from "../icons";
import {Details} from "./details/description/";
import { Chart } from "react-google-charts";




import { Link } from 'react-router-dom';
//import {LoadingBar} from "../LoadingBar";

const url = require("../data/url");

export const data2 = [
    ["Sequences", "Total", "Uniprot" , "CampR3", "Yadamp" , "Dbaasp" , "Dramp" , "dbAMP" , "APD3" , "Lamp2"],
    ["Database", 89675, 0, 8164,2636,19046,6832,30990,3575,7577],

];

export const options = {
    chart: {
        title: "Sequence count",
        subtitle: "How many different sequences are from each database",
    },
};


fetch("https://compass-api.mathematik.uni-marburg.de/overview.php", {
    headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }})
.then(response => response.json())
    .then((jsonData) => {
        // jsonData is parsed json object received from url
        data2[1][1]=jsonData["sequences"]["total"]
        data2[1][2]=jsonData["sequences"]["uniprot"]
        data2[1][3]=jsonData["sequences"]["camp"]
        data2[1][4]=jsonData["sequences"]["yadamp"]
        data2[1][5]=jsonData["sequences"]["dbaasp"]
        data2[1][6]=jsonData["sequences"]["dramp"]
        data2[1][7]=jsonData["sequences"]["dbAMP"]
        data2[1][8]=jsonData["sequences"]["apd3"]
        data2[1][9]=jsonData["sequences"]["lamp2"]


    })
    .catch((error) => {


        // handle your errors here
        console.error(error)
    })



export class Overview extends Component {





    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading:true
        };
    }

    state = { loading: true };







    render() {
        let url = require(".././data/url");




        const {data,loading} = this.state;

        return (
            <Box fill align="start" justify="center">
                <Heading>Data Overview</Heading>



                <Chart
                    chartType="Bar"
                    width="50%"
                    height="400px"
                    data={data2}
                    options={options}
                />




                <span>&nbsp;&nbsp;</span>
                <span>&nbsp;&nbsp;</span>
            </Box>


        );

    }
}
