import React, { useEffect, useRef, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import {Box, IconButton, Tooltip, Typography} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
    QueryClient,
    QueryClientProvider,
    useQuery,
} from '@tanstack/react-query';
import {Link} from "react-router-dom";


const Example = () => {
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const rowVirtualizerInstanceRef = useRef(null);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    useEffect(() => {
        //scroll to the top of the table when the sorting changes
        try {
            rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
        } catch (error) {
            console.error(error);
        }
    }, [sorting]);

    const { data, isError, isFetching, isLoading, refetch } = useQuery({
        queryKey: [
            'table-data',
            columnFilters, //refetch when columnFilters changes
            globalFilter, //refetch when globalFilter changes
            pagination.pageIndex, //refetch when pagination.pageIndex changes
            pagination.pageSize, //refetch when pagination.pageSize changes
            sorting, //refetch when sorting changes
        ],
        queryFn: async () => {
            const fetchURL = new URL(
                'https://compass-api.mathematik.uni-marburg.de/entrys_api.php',
                process.env.NODE_ENV === 'production'
                    ? 'https://www.material-react-table.com'
                    : 'https://compass-api.mathematik.uni-marburg.de/entrys.php',
            );
            fetchURL.searchParams.set(
                'start',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            fetchURL.searchParams.set('size', `${pagination.pageSize}`);
            fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
            fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
            fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

            const response = await fetch(fetchURL.href);
            const json = await response.json();
            return json;
        },
        keepPreviousData: true,
    });

    const columns = useMemo(
        () => [
            {
                accessorKey: 'entry_id',
                header: 'ID',
                size: 50,
                cell: rowData => <Link to={{ pathname: `/AMP/${rowData.sequence_id}` }}>{rowData.sequence_id}</Link>,
            },
            {
                accessorKey: 'name',
                header: 'Name',
            },
            {
                accessorKey: 'source',
                header: 'Source',
            },
            {
                accessorKey: 'activity',
                header: 'Activity',
                size: 50,
            },
            {
                accessorKey: 'gram',
                header: 'Gram',
            },
            {
                accessorKey: 'target',
                header: 'Target',
            },
        ],
        [],
    );

    return (
        <MaterialReactTable
            columns={columns}
            data={data?.data ?? []} //data is undefined on first render

            manualPagination




            muiToolbarAlertBannerProps={
                isError
                    ? {
                        color: 'error',
                        children: 'Error loading data',
                    }
                    : undefined
            }
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={setGlobalFilter}
            onPaginationChange={setPagination}
            onSortingChange={setSorting}
            initialState={{ showColumnFilters: false }}
            renderTopToolbarCustomActions={() => (
                <Tooltip arrow title="Refresh Data">
                    <IconButton onClick={() => refetch()}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            )}
            rowCount={data?.meta?.totalRowCount ?? 0}
            state={{

                globalFilter,
                isLoading,
                pagination,
                density:'comfortable',

                showAlertBanner: isError,
                showProgressBars: isFetching,
                sorting,
            }}
            renderDetailPanel={({ row }) => (
                <Box
                    sx={{
                        display: 'grid',
                        margin: 'auto',
                        gridTemplateColumns: '1fr 1fr',
                        width: '100%',
                    }}
                >
                    <Typography>Data fetched from: {row.original.database}</Typography>
                    <Typography>Database ID for origin: {row.original.db_id}</Typography>
                </Box>
            )}
        />
    );
};

const queryClient = new QueryClient();

const ExampleWithReactQueryProvider = () => (
    <QueryClientProvider client={queryClient}>
        <Example />
    </QueryClientProvider>
);

export default ExampleWithReactQueryProvider;

