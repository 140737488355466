import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Box, Heading} from 'grommet';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import {Researches} from "./details/description/Researches";
import {OtherPublications} from "./details/description/OtherPublications";
import {createTheme, MuiThemeProvider, withStyles} from "@material-ui/core/styles";
import "./Publications.css";


const theme = createTheme({
    palette: {

        primary: {main: "#76B7B2",},
        secondary: {main: "#76B7B2",},

    },
    typography: {useNextVariants: true},
});

const NewButton = withStyles({


    root: {


        clear: "center",
        textTransform: 'none',
        fontSize: 16,
        fontWeight: 'bold',
        align: 'left',
        alignItems: 'left',
        color: "#000",

        disableRipple:'false',
        //textAlign:'left',
        paddingLeft: '20px',
        padding: '20px 20px',
        //display: "inline-block",
        // border: '1px solid',
        lineHeight: 1.5,
        width: '100%',
        outline: "none",
        //justifyContent: "left",
        '&:hover': {
            backgroundColor: "#E3FDEE",
            borderColor: "rgba(118,183,178,0.5)",
            boxShadow: 'none',
        },
        '&:label': {
            Color: "rgba(118,183,178,0.5)",
            borderColor: "#76B7B2",
            boxShadow: 'none',
        },
        '&:wrapper': {
            paddingLeft: '0px',
            alignItems: 'start',
        },


        '&:focus': {
            color: "#76B7B2",
            outlineColor: "rgba(118,183,178,0.5)",


        },

    },
})(Tab);


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


export class Publications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0
        };
    }


    handleChange = (event, newValue) => {
        this.setState({value: newValue})

    };




    render() {
        const {data, researches} = this.props;
        const {value} = this.state;
        return (

            <Box fill align="start" justify="center">
                <Heading>Publications</Heading>
                <MuiThemeProvider theme={theme}>


                    <Box fill={"horizontal"}  elevation="xxlarge" background={"white"} >



                        <Tabs
                        value={value}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"



                    >
                        <NewButton label="Research Publications" style={{borderColor:"black important!", borderStyle:"solid"}}/>
                        <NewButton label="Other Publications"/>
                    </Tabs>




                        </Box>
                </MuiThemeProvider>
                <TabPanel value={value} index={0}>
                    <Researches
                        data={researches}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <OtherPublications
                        data={data}/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    Item Three
                </TabPanel>


            </Box>


        );
    }
}
