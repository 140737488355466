import React, { useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
    QueryClient,
    QueryClientProvider,
    useQuery,
} from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import {Link as MuiLink, Box, Typography } from '@mui/material';



const SequenceTable = () => {
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const { data, isError, isFetching, isLoading, refetch } = useQuery({
        queryKey: [
            'table-data',
            columnFilters, //refetch when columnFilters changes
            globalFilter, //refetch when globalFilter changes
            pagination.pageIndex, //refetch when pagination.pageIndex changes
            pagination.pageSize, //refetch when pagination.pageSize changes
            sorting, //refetch when sorting changes
        ],
        queryFn: async () => {
            const fetchURL = new URL(
                'https://compass-api.mathematik.uni-marburg.de/sequence_api.php',
                process.env.NODE_ENV === 'production'
                    ? 'https://www.material-react-table.com'
                    : 'https://compass-api.mathematik.uni-marburg.de/sequence.php',
            );
            fetchURL.searchParams.set(
                'start',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            fetchURL.searchParams.set('size', `${pagination.pageSize}`);
            fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
            fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
            fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

            const response = await fetch(fetchURL.href);
            const json = await response.json();
            return json;
        },
        keepPreviousData: true,
    });

    const columns = useMemo(
        () => [
            {
                accessorKey: 'sequence_id',
                header: 'ID',
                size: 50,
                Cell: ({ cell, row }) => <Link to={{ pathname: `/AMP/${row.original?.sequence_id}` }}>{row.original?.sequence_id}</Link>,
            /*
                Cell: ({ cell, row }) => (
                    <Link href={`AMP/${row.original?.sequence_id}`} passHref legacyBehavior>
                        <MuiLink
                            target={
                                cell.getValue()
                                    ? '_self'
                                    : undefined
                            }
                            rel="noopener"
                        >
                            {row.original?.sequence_id}
                        </MuiLink>
                    </Link>
                ),
            */


            },
            {
                accessorKey: 'name',
                header: 'Name',

            },
            {
                accessorKey: 'sequence',
                header: 'Sequence',
            },


        ],
        [],
    );

    return (
        <MaterialReactTable
            columns={columns}
            data={data?.data ?? []} //data is undefined on first render
            initialState={{ showColumnFilters: false }}
            manualFiltering
            manualPagination
            manualSorting
            muiToolbarAlertBannerProps={
                isError
                    ? {
                        color: 'error',
                        children: 'Error loading data',
                    }
                    : undefined
            }
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={setGlobalFilter}
            onPaginationChange={setPagination}
            onSortingChange={setSorting}
            renderTopToolbarCustomActions={() => (
                <Tooltip arrow title="Refresh Data">
                    <IconButton onClick={() => refetch()}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            )}
            rowCount={data?.meta?.totalRowCount ?? 0}
            state={{
                columnFilters,
                globalFilter,
                isLoading,
                pagination,
                density:'compact',
                showAlertBanner: isError,
                showProgressBars: isFetching,
                sorting,
            }}
            renderDetailPanel={({ row }) => (
                <Box
                    sx={{
                        display: 'grid',
                        margin: 'auto',
                        gridTemplateColumns: '1fr 1fr',
                        width: '100%',
                    }}
                >
                    <div>
                        {row.original.camp_id != null && <p>&#128994;Camp</p>}
                        {row.original.camp_id == null && <p>&#128308;Camp</p>}

                        {row.original.yadamp_id != null && <p>&#128994;yadamp</p>}
                        {row.original.yadamp_id == null && <p>&#128308;yadamp</p>}

                        {row.original.dbaasp_id != null && <p>&#128994;dbaasp</p>}
                        {row.original.dbaasp_id == null && <p>&#128308;dbaasp</p>}

                        {row.original.dramp_id != null && <p>&#128994;dramp</p>}
                        {row.original.dramp_id == null && <p>&#128308;dramp</p>}

                        {row.original.dbAMP_id != null && <p>&#128994;dbAMP</p>}
                        {row.original.dbAMP_id == null && <p>&#128308;dbAMP</p>}

                        {row.original.apd3_id != null && <p>&#128994;apd3</p>}
                        {row.original.apd3_id == null && <p>&#128308;apd3</p>}

                        {row.original.lamp2_id != null && <p>&#128994;lamp2</p>}
                        {row.original.lamp2_id == null && <p>&#128308;lamp2</p>}

                        {row.original.uniprot_id != null && <p>&#128994;uniprot</p>}
                        {row.original.uniprot_id == null && <p>&#128308;uniprot</p>}

                    </div>


                </Box>
            )}
        />
    );
};

const queryClient = new QueryClient();

const ExampleWithReactQueryProvider = () => (
    <QueryClientProvider client={queryClient}>
        <SequenceTable />
    </QueryClientProvider>
);

export default ExampleWithReactQueryProvider;

