import React from "react";
import PropTypes from "prop-types";
import {withRouter, matchPath} from "react-router-dom";
import {withStyles,} from '@material-ui/core/styles';
import {Tab} from "@material-ui/core";
import "./Button.css";



const NewButton = withStyles({


    root: {
        clear: "center",
        textTransform: 'none',
        fontSize: 18,
        //align: 'left',
        //alignItems: 'left',
        color: "#000",

        //textAlign:'left',
        paddingLeft: '10px',
        padding: '10px 10px',
        display: "inline-block",
        //border: '1px solid',
        borderColor: "grey",
        //lineHeight: 1.5,

        width: '100%',
        outline: "none",
        //justifyContent: "left",
        '&:hover': {
            backgroundColor: "#cadded",
           // borderColor: "#FF1616",
            //boxShadow: "#88b9e4",
        },
        '&:label': {
           // Color: "#F7BCB8",
           // borderColor: "#FF1616",
            boxShadow: 'none',
        },
        '&:wrapper': {
            paddingLeft: '0px',
            alignItems: 'start',
        },

        '&:active': {
            boxShadow: 'none',
            //backgroundColor: "#F7BCB8",


        },
        '&:focus': {
            color: "#88b9e4",
           // outlineColor: "#FF1616",
            //backgroundColor: "#BCB8B1",

        },

    },
})(Tab);

class RoutedButton extends React.Component {



    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        path: PropTypes.string.isRequired,

    };


    onClick = (event) => {
        //this.updateReview();
        const {history, path,} = this.props;
        event.preventDefault();
        history.push(path);


    };


    static contextTypes = {
        router: PropTypes.object
    };

    render() {


        const {
            active,
            exact,
            location,
            history,
            path,
            strict,
            items,
            staticContext,
            id,
            onClick,
            value,
            label,
            symbol,
            ...rest
        } = this.props;
        // const pathMatch = matchPath(location.pathname, {exact, path, strict});

        const {router} = this.context;

        return (

            <>
                <div>


                    <NewButton
                        //size="large"
                        className="sidebar-item"
                        //active={active && !!pathMatch}
                        value={value}
                        onClick={this.onClick}
                        id={id}
                        symbol={symbol}


                        active={
                            active ||
                            (router && !!matchPath(router.location.pathname, {path}))
                        }

                        path={path}
                        label={<>
                            <div> {label}</div>
                        </>}


                        {...rest}


                    >


                    </NewButton>

                </div>
            </>

        );
    }
}

export default withRouter(RoutedButton);

