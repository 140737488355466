import { grommet } from "grommet";
import { deepMerge } from "grommet/utils";

export const theme = deepMerge(grommet, {

    "global": {
        "size": {
            //sidebar: "200px"
        }, font: {
            family: "Arial, Helvetica, sans-serif",
            size: "14px"
        },

        "colors": {
            "brand":"#83b4df",
            "border": {
                "dark": "#FCF5E5",
                "light": "#444444"
            },
            "background": "#ffffff",
            "placeholder": "#ebebeb",
        },


    },




});
